import React from 'react';
import styled from 'styled-components';
import { GlobalStyle } from 'utils/global';
import './fonts/fonts.css';

const Wrapper = styled.div`
 position: relative;
 height: 100vh;
`

const Layout = (props:any) => {
  const {
    children
  } = props;

  return (
    <Wrapper>
      <GlobalStyle />
      {children}
    </Wrapper>
  )
}

export default Layout;