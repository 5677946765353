import React, { useEffect, useState, useRef, useCallback } from "react"
import styled from 'styled-components';
import { StaticImage } from "gatsby-plugin-image"
import { gsap } from "gsap";
import { ScrollTrigger, ScrollToPlugin } from 'gsap/all';
import Layout from "components/Layout/Layout"
import ScrollButton from "components/ScrollButton/ScrollButton";
import Anchor from "components/Anchor/Anchor";
import { Seo } from "components/Seo/Seo";
import { fontstack } from "utils/fontstack";
import { SiteContext } from 'context/site-context';

// markup
gsap.registerPlugin(ScrollTrigger,ScrollToPlugin);

const Layers = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
`

const LayersOne = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
`

const LayersTwo = styled(LayersOne)` 
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(0px, 0%) perspective(2000px) rotateX(180deg);
  opacity: 0.2;
  z-index: 0;
`

const Layer = styled.div`
  width: 100%;
  height: 100%;
  padding: 40px;
  box-sizing: border-box;
`

const LayerInner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

const TextContent = styled.div`
  ${fontstack.secondary}
  font-size: 20px;
  line-height: 24px;
  max-width: 454px;
  width: 100%;
  color:  var(--theme-black);
  text-align: justify;
  text-indent: 60px;
`

const Content = styled.div` 
  position: relative;
  width: 400px;
`

const ContentInner = styled.div` 
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
`

const Video = styled.video`
  width: 100%;
  display:block;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
`

const Canvas = styled.canvas` 
  width: 100%;
  height: 100%;
  display:block;
  left: 0;
  top: 0;
  position: absolute;
`

const LinkText = styled(Anchor)`
  ${fontstack.default}
  font-size: 14px;
  line-height: 20px;
  color: var(--theme-black);
`

const BottomContainer = styled.div`
  position: absolute;
  bottom: 0;
`

const ItalicText = styled.span`
   ${fontstack.secondary}
   font-style: italic;
`

const IconText = styled.span`
  ${fontstack.icons}
`
const IndexPage = () => {
  const  Trigger = useRef<ScrollTrigger>();
  const { width } = React.useContext(SiteContext);
  const [ LayersRef, setLayers ] = useState<HTMLDivElement>();
  const [ LayerOne, setLayerOne ] = useState<HTMLDivElement>();
  const [ LayerTwo, setLayerTwo ] = useState<HTMLDivElement>();
  const [ VideoRef, setVideoRef ] = useState<HTMLVideoElement>();
  const [ CanvasOneRef, setCanvasOneRef ] = useState<HTMLVideoElement>();
  const [ CanvasTwoRef, setCanvasTwoRef ] = useState<HTMLVideoElement>();
  const [ CanvasThreeRef, setCanvasThreeRef ] = useState<HTMLVideoElement>();

  const wrapping = useRef(false);

  useEffect(()=>{
    if(LayersRef && LayerOne && LayerTwo && CanvasOneRef && CanvasTwoRef && CanvasThreeRef) {
      let currentTime = 0;
      let playingVideo:HTMLVideoElement;
      const percentageLength = (LayerOne.childNodes.length - 1) * 100;
      ScrollTrigger.matchMedia({
        "(min-width: 768px)": function() {
          Trigger.current = ScrollTrigger.create({
            start: 0,
            end: "+=3000",
            pin: LayersRef,
            onUpdate: (self) => {
              if (self.progress === 1 && self.direction > 0 && !wrapping.current) {
                wrapForward(self);
              } else if(self.progress < 1e-5 && self.direction < 0 && !wrapping.current) {
                wrapBackward(self);
              } else {
                wrapping.current = false;
                gsap.set(LayerOne,{y:`${self.progress*-percentageLength}%`});
                gsap.set(LayerTwo,{y:`${self.progress*percentageLength}%`});
              }
             
            }
          })
        },
        "(max-width: 767px)": function() {
          Trigger.current = ScrollTrigger.create({
            start: 0,
            end: "+=3000",
            pin: LayersRef,
            onUpdate: (self) => {
              gsap.set(LayerOne,{y:`${self.progress*-percentageLength}%`});
              gsap.set(LayerTwo,{y:`${self.progress*percentageLength}%`});
            }
          })
        }
      })


      const timeUpdate = (e) => {
        currentTime = e.target.currentTime;
      }

      const sync = () => {
        if(playingVideo) {
          currentTime = playingVideo.currentTime;
        }
        requestAnimationFrame(sync);
      }

      sync();

      CanvasOneRef.setAttribute('label','1');
      CanvasTwoRef.setAttribute('label','2');
      CanvasThreeRef.setAttribute('label','3');
      let observer = new IntersectionObserver((entries, observer) => { 
        entries.forEach(entry => {
          const video = entry.target as HTMLVideoElement;
         if(entry.intersectionRatio === 0 ){
          
          } else {
            video.currentTime = currentTime;
            playingVideo = video;
          }
          
    
        });
      }, {threshold: 0});

      observer.observe(CanvasOneRef);
      observer.observe(CanvasTwoRef);
      observer.observe(CanvasThreeRef);


      /*const videos = [CanvasOneRef,CanvasTwoRef,CanvasTwoRef];
      videos.forEach((video,index) => {
        const v = video;

        v.addEventListener('timeupdate', function() {
        
          console.log(`${index} current time ${this.currentTime}`);
        }, false);
  

        v.addEventListener('play', function() {
        
          console.log(`${index} play`);
        }, false);
  
        v.addEventListener('pause', function() {
          
          console.log(`${index} pause`);
        }, false);
      })*/

  
      return () => {
        if(Trigger.current) {
          Trigger.current.kill();
        }
      }
    } 
  },[LayersRef, LayerOne, LayerTwo, CanvasOneRef, CanvasTwoRef, CanvasThreeRef]);

  const LayerOneReference = useCallback((node)=> {
    if(node !== null) {
      setLayerOne(node);
    }

  },[])

  const LayerTwoReference = useCallback((node)=> {
    if(node !== null) {
      setLayerTwo(node);
    }

  },[])

  const CanvasOneReference = useCallback((node)=>{
    if(node !== null) {
      setCanvasOneRef(node);
    }
  },[]);

  const CanvasTwoReference = useCallback((node)=>{
    if(node !== null) {
      setCanvasTwoRef(node);
    }
  },[]);

  const CanvasThreeReference = useCallback((node)=>{
    if(node !== null) {
      setCanvasThreeRef(node);
    }
  },[]);

  const LayerReference = useCallback((node)=>{
    if(node !== null) {
      setLayers(node);
    }
  },[])

  const wrapBackward = (trigger:ScrollTrigger) => {
    wrapping.current = true;
    trigger.scroll(ScrollTrigger.maxScroll(window) - 1);
  }

  const wrapForward = (trigger:ScrollTrigger) => {
    wrapping.current = true;
    trigger.scroll(1);
  }

  const scrollTo = () => {
    gsap.to(window, {duration: 1, scrollTo: window.scrollY + 300});
  }


  return (
    <Layout>
      <Seo />
      <Layers ref={LayerReference}>
        <LayersOne ref={LayerOneReference}>
          <CopyFrame scrollTo={scrollTo}/>
          <MediaFrame ref={CanvasOneReference} />
          <CopyFrame scrollTo={scrollTo}/>
        </LayersOne>
        <LayersTwo ref={LayerTwoReference}>
          <MediaFrame ref={CanvasTwoReference}/>
          <CopyFrame scrollTo={scrollTo} />
          <MediaFrame ref={CanvasThreeReference}/>
        </LayersTwo>
      </Layers>
    </Layout>
  )
}

export default IndexPage


const CopyFrame = (props:any) => {
  const {
    scrollTo
  } = props;
  return (
    <Layer>
      <LayerInner>
        <TextContent>
          <ItalicText>Manual Engineering</ItalicText><IconText>©</IconText> is a internet studio<IconText>⌘</IconText> based in Hong Kong<IconText>香港</IconText>. We specialise in building things for the internet<IconText>☼</IconText>.
        </TextContent>
        <BottomContainer>
          <ScrollButton onClick={scrollTo}/>
        </BottomContainer>
      </LayerInner>
    </Layer>
  )
}

const MediaFrame = React.forwardRef<HTMLVideoElement>((props,ref) => {

  return (
    <Layer>
      <LayerInner>
        <Content>
          <ContentInner>
            <Video ref={ref} poster='/Manual_Still.jpg' autoPlay playsInline muted loop>
              <source src="https://prismic-io.s3.amazonaws.com/seensounds/7cef71ff-2e8f-4434-929d-e033ce92c5e6_Manual_Placeholder_720_2MB.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </Video>
          </ContentInner>
        </Content>
        <BottomContainer>
          <LinkText href="mailto:info@manualengineering.com">info@manualengineering.com</LinkText>
        </BottomContainer>
      </LayerInner>
    </Layer>
  )
})

const CanvasFrame = React.forwardRef<HTMLCanvasElement>((props,ref) => {

  return (
    <Layer>
      <LayerInner>
        <Content>
          <ContentInner>
            <Canvas ref={ref} width="800" height="450"/>
          </ContentInner>
        </Content>
        <BottomContainer>
          <LinkText>Contact</LinkText>
        </BottomContainer>
      </LayerInner>
    </Layer>
  )
})