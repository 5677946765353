import React, { Props, useEffect, useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.a`
  transition: opacity .2s ease;
  color: var(--theme-black);
  &:hover {
    opacity: 0.5;
  }
`
const Anchor = (props:any) => {
  const { children, ...rest } = props;

  return (
    <Wrapper {...rest}>
      {children}
    </Wrapper>
  )
}

export default Anchor;