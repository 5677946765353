import React from 'react';
import styled from 'styled-components';
import { fontstack } from "utils/fontstack";


const Wrapper = styled.button`
  ${fontstack.icons}
  font-size: 14px;
  position: relative;
  padding: 10px;
  margin: 0;
  background: transparent;
  outline: 0;
  color: var(--theme-black);
  border-radius: 10px;
  border-width: 0.4px;
  border-style: solid;
  border-color: var(--theme-black);
  cursor: pointer;
`

const  ScrollButton = (props:any) => {
  const { ...rest } = props;
  return (
    <Wrapper {...rest}>
      ↕
    </Wrapper>
  )
}

export default ScrollButton;