import { css } from 'styled-components';

const stacks = {
  // ns = not small
  default: () =>
    css`
        font-family: 'Neue Montreal';
    `,
  secondary: () =>
    css`
      font-family: 'Italian Garamond';
    `,
  icons: () =>
  css`
      font-family: 'Inter';
  `
}
export const fontstack = stacks;